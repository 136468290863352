import {RETRIEVE_TRANSACTION} from "../actions/transaction"

export default function transaction(state = {}, action){
    switch (action.type){
        case RETRIEVE_TRANSACTION:
            return{
                ...state,
                ...action.transactions
            }
        default:
            return state
    }
}