import {ALL_FEED} from "../actions/feed";
import _ from "lodash";

export default function feed(state = {}, action){
    switch(action.type){
        case ALL_FEED:
            return{
                ...state, ..._.mapKeys(action.feed, "id")
            }
        default:
            return state
    }
}