import React, {Component, Suspense, lazy} from "react";
import {connect} from 'react-redux'
import {withAuth0} from "@auth0/auth0-react";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import LoadingBar from 'react-redux-loading-bar'
import {handleInitialData} from "./redux/actions/shared";
import {handleUserRequest} from "./redux/actions/request";
import {handleAllFeed} from "./redux/actions/feed";
import {handleEmploymentData} from "./redux/actions/employment";
import {handleGetConnection} from "./redux/actions/connection";
import {handleDiscoveringUser} from "./redux/actions/users";
import {handleViewInvitations} from "./redux/actions/invitation";
import {handleUserFeed} from "./redux/actions/userFeed";
import {handleAccountBalance} from "./redux/actions/balance";
import {handleRating} from "./redux/actions/authedUser";
import {handleUserProposal} from "./redux/actions/proposal";
import {handleTransactions} from "./redux/actions/transaction";
import {handleRetrieveNotification} from "./redux/actions/notification";

const SetupPage = lazy(()=>import("./pages/Setup/index"));
const SetupAccountPage = lazy(()=>import("./pages/Setup/Account/index"));
const SetupConsentPage = lazy(()=>import("./pages/Setup/Consent/index"));
const HomePage = lazy(()=>import("./pages/Home/index"));
const ProfilePage = lazy(()=>import("./pages/Profile/index"));
const SettingPage = lazy(()=>import("./pages/Settings/index"));
const Page404 = lazy(()=>import("./pages/Error/404/index"))
const NotificationPage = lazy(()=>import("./pages/Notification/index"));
const SetupPaymentPage = lazy(()=>import("./pages/Setup/Payment/index"));
const RequestPage = lazy(()=>import("./pages/Request/index"));
const RequestingPage = lazy(()=>import("./pages/Request/Requesting/index"));
const ProposalPage = lazy(()=>import("./pages/Proposal/index"));
const ProposingPage = lazy(()=>import("./pages/Proposal/Proposing/index"))
const UsersPage = lazy(()=>import("./pages/Users/index"));
const MentionPage = lazy(()=>import("./pages/Mention/index"));
const LearningPage = lazy(()=>import("./pages/Learning/index"));
const SearchPage = lazy(()=>import("./pages/Search/index"));
const AchievementPage = lazy(()=>import("./pages/Achievement/index"));
const AgreementPage = lazy(()=>import("./pages/Agreement/index"));
const LoanPage = lazy(()=>import("./pages/Loan/index"));
const PaymentPage = lazy(()=>import("./pages/Payment/index"));
const ElectronicAgreementPage = lazy(()=>import("./pages/Setup/Electronic/index"));
const TransactionPage = lazy(()=>import("./pages/Transaction/index"));
const ConnectionPage = lazy(()=>import("./pages/Connection/index"));
const InvestmentPage = lazy(()=>import("./pages/Investment/index"));


const CreateProfile = lazy(()=>import("./pages/CreateProfilePage"))


class App extends Component{
    state = {
        "setupCompleted":true,
    }
    componentDidMount() {
        this.retrieveToken()
            .then(result =>{
                this.props.dispatch(handleInitialData(result))
                    .then(data=>{
                        const {requirement} = this.props
                        console.log(requirement)
                        if(requirement.initialization === false){
                            this.setState({
                                setupCompleted: false
                            })
                        }
                        else {
                            // user's rating
                            this.props.dispatch(handleRating(result))
                            // user's account balance
                            this.props.dispatch(handleAccountBalance(result))
                            this.props.dispatch(handleRetrieveNotification(result))
                            this.props.dispatch(handleUserRequest(result))
                            this.props.dispatch(handleUserProposal(result))
                            this.props.dispatch(handleAllFeed(result))
                            this.props.dispatch(handleUserFeed(result))
                            this.props.dispatch(handleEmploymentData(result))
                            this.props.dispatch(handleDiscoveringUser(result))
                            //this.props.dispatch(handleGetConnection(result))
                            this.props.dispatch(handleGetConnection(result))
                            this.props.dispatch(handleViewInvitations(result))
                            this.props.dispatch(handleTransactions(result))
                        }
                    })
                    .catch(error=> console.log('error',error))
            } )
            .catch(error=> console.log('error',error))
    }
    async retrieveToken(){
        const {getAccessTokenSilently} = this.props.auth0
        let accessToken;
        try{
            accessToken = await getAccessTokenSilently()
        }catch (e){
            console.error(e);
        }
        return accessToken;
    }
    render() {
        const {isLoading} = this.props.auth0;
        if (isLoading){
            return (
                <LoadingBar/>
            )
        }
        return(
            <Router>
                <Suspense fallback={<LoadingBar />}>
                    <Routes>
                        <Route path='/' exact element={
                            this.state.setupCompleted===true
                                ?<HomePage/>
                                :<SetupPage/>
                        }/>;
                        <Route path='*' exact element={<Page404/>}/>
                        <Route path='/profile' exact element={<ProfilePage/>}/>
                        <Route path='/circle' exact element={<ConnectionPage/>}/>
                        <Route path='/achievement' exact element={<AchievementPage/>}/>
                        <Route path='/learning' exact element={<LearningPage/>}/>
                        <Route path='/users/:id' exact element={<UsersPage/>}/>
                        <Route path='/mention/:mention' exact element={<MentionPage/>}/>
                        <Route path='/settings' exact element={<SettingPage/>}/>
                        <Route path='/requests' exact element={<RequestPage/>}/>
                        <Route path='/requests/:id' exact element={<RequestingPage/>}/>
                        <Route path='/proposals' exact element={<ProposalPage/>}/>
                        <Route path='/proposals/:id' exact element={<ProposingPage/>}/>
                        <Route path='/agreement/:id' exact element={<AgreementPage/>}/>
                        <Route path='/loan/:id' exact element={<LoanPage/>}/>
                        <Route path='/investment/:id' exact element={<InvestmentPage/>}/>
                        <Route path='/payment/:id' exact element={<PaymentPage/>}/>
                        <Route path='/transaction/:id' exact element={<TransactionPage/>}/>
                        <Route path='/notifications' exact element={<NotificationPage/>}/>
                        <Route path='/discover' exact element={<SearchPage/>}/>
                        <Route path='/setup/account' exact element={<SetupAccountPage/>}/>
                        <Route path='/setup/consent' exact element={<SetupConsentPage/>}/>
                        <Route path='/setup/electronic-consent' exact element={<ElectronicAgreementPage/>}/>
                        <Route path='/setup/payment' exact element={<SetupPaymentPage/>}/>
                        <Route path='/setup/tutorial' exact element={<NotificationPage/>}/>
                        <Route path='/setup/income' exact element={<NotificationPage/>}/>
                        <Route path='/setup/address' exact element={<NotificationPage/>}/>
                    </Routes>
                </Suspense>
            </Router>
        )
    }
}

function mapStateToProps({authedUser, requirement}){
    return {
        profile :authedUser,
        requirement
    }
}

export default connect(mapStateToProps)(withAuth0(App))

