import {hideLoading, showLoading} from "react-redux-loading-bar";
import {getUserFinancialTransactionAPI} from "../../utils/api/account";

export const RETRIEVE_TRANSACTION = 'RETRIEVE_TRANSACTION'

export function retrieveTransaction(transactions){
    return{
        type: RETRIEVE_TRANSACTION,
        transactions
    }
}

export function handleTransactions(token){
    return (dispatch)=> {
        dispatch(showLoading())
        getUserFinancialTransactionAPI(token)
            .then(transactions => {
                dispatch(retrieveTransaction(transactions))
            })
            .then(() => dispatch(hideLoading()))
    }
}