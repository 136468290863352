import {showLoading, hideLoading} from "react-redux-loading-bar";
import {getUserLoanRequest, createLoanRequest} from '../../utils/api/loanAPI'
import {handleAllFeed} from "./feed";
import {handleUserFeed} from "./userFeed";
import {errorOccurred} from "./error";

export const CREATE_LOAN_REQUEST = 'CREATE_LOAN_REQUEST'
export const USER_LOAN_REQUEST = 'USER_LOAN_REQUEST'

export function viewUserLoanRequest(requests){
    return{
        type:USER_LOAN_REQUEST,
        requests,
    }
}

export function createUserLoanRequest(request){
    return{
        type:CREATE_LOAN_REQUEST,
        request,
    }
}

export function handleUserRequest(token){
    return(dispatch)=>{
        dispatch(showLoading())
        return getUserLoanRequest(token)
            .then((request)=>{
                dispatch(viewUserLoanRequest(request))
            })
            .then(()=>dispatch(hideLoading()))
    }
}

export function createUserRequest(token, body){
    return(dispatch)=>{
        let formData = new FormData()
        if (body.hasOwnProperty("image")){
            formData.append('image', body.image, body.image.name)
        }
        formData.append('amount', body.amount)
        formData.append('comment', body.comment)
        formData.append('title', body.title)
        formData.append('length', body.length)
        dispatch(showLoading())
        return createLoanRequest(token,formData)
            .then((data)=>{
                if (data.hasOwnProperty("loanTicket")){
                    dispatch(createUserLoanRequest(data.loanTicket))
                    dispatch(handleAllFeed(token))
                    dispatch(handleUserFeed(token))
                }
                else {
                    dispatch(errorOccurred(data))
                }
            })
            .then(()=>dispatch(hideLoading()))
            .catch(error =>{
                console.log(error)
            })
    }
}