import {showLoading, hideLoading} from "react-redux-loading-bar";
import {getALLFeedAPI} from "../../utils/api/feedAPI";

export const ALL_FEED = 'ALL_FEED'



export function getAllFeed(feed){
    return{
        type:ALL_FEED,
        feed,
    }
}

export function handleAllFeed(token){
    return(dispatch)=>{
        dispatch(showLoading())
        return getALLFeedAPI(token)
            .then((feed)=>{
                dispatch(getAllFeed(feed))
            })
            .then(()=>dispatch(hideLoading()))
    }
}