import {showLoading, hideLoading} from "react-redux-loading-bar";
import {createLoanProposal, getUserLoanProposal} from "../../utils/api/loanAPI";
import {handleAllFeed} from "./feed";
import {handleUserFeed} from "./userFeed";

export const CREATE_LOAN_PROPOSAL = "CREATE_LOAN_PROPOSAL"
export const USER_LOAN_PROPOSAL = "USER_LOAN_PROPOSAL"

export function viewUserLoanProposal(proposals){
    return{
        type: USER_LOAN_PROPOSAL,
        proposals
    }
}

export function createUserLoanProposal(proposal){
    return{
        type: CREATE_LOAN_PROPOSAL,
        proposal
    }
}


export function handleUserProposal(token){
    return(dispatch)=>{
        dispatch(showLoading())
        return getUserLoanProposal(token)
            .then((proposals)=>{
                dispatch(viewUserLoanProposal(proposals))
            })
            .then(()=>dispatch(hideLoading()))
    }
}

export function createUserProposal(token, body){
    return(dispatch)=>{
        let formData = new FormData()
        if (body.hasOwnProperty("image")){
            formData.append('image', body.image, body.image.name)
        }
        formData.append('amount', body.amount)
        formData.append('rate', body.rate)
        formData.append('comment', body.comment)
        formData.append('title', body.title)
        formData.append('length', body.length)
        formData.append('minScore', body.minScore)
        formData.append('maxScore', body.maxScore)
        formData.append('idVerified', body.idVerified)
        formData.append('addressVerified', body.addressVerified)
        formData.append('incomeVerified', body.incomeVerified)
        formData.append('isConnected', body.isConnected)
        dispatch(showLoading())
        return createLoanProposal(token, formData)
            .then(data=>{
                dispatch(createUserLoanProposal(data.loanProposal))
                dispatch(handleAllFeed(token))
                dispatch(handleUserFeed(token))
            })
            .then(()=>dispatch(hideLoading()))
            .catch(error =>{
                console.log("error")
            })
    }
}