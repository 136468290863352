import {showLoading, hideLoading} from "react-redux-loading-bar";
import {getUserFeedAPI} from "../../utils/api/feedAPI";

export const USER_FEED = 'USER_FEED'

export function getUserFeed(userFeed){
    return{
        type:USER_FEED,
        userFeed,
    }
}

export function handleUserFeed(token){
    return(dispatch)=>{
        dispatch(showLoading())
        return getUserFeedAPI(token)
            .then((userFeed)=>{
                dispatch(getUserFeed(userFeed))
            })
            .then(()=>dispatch(hideLoading()))
    }
}