 const API_URL = process.env.REACT_APP_API_URL

 /*
  * Get user initial information
  */
export const getUser = async (authToken) => {
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/user/`, {
        mode: "cors",
        headers
    })
        .then(res => res.json())
        .then(data => {
            return data
        })
        .catch(error => console.log("error", error))
}
/*
* Verify if username is taken
 */
export const verifyUsername = async (authToken, body) =>
 fetch(`${API_URL}/user/username/`,{
     method:'POST',
     mode: "cors",
     headers:{
         'Authorization': `Bearer ${authToken}`,
         'Content-Type':'application/json',
     },
     body:JSON.stringify(body)
 }).then(res => res.json())
     .catch(error=> console.log('error',error))

export const getRating = async (authToken) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return fetch(`${API_URL}/user/rating/`, {
        mode: "cors",
        headers
    })
        .then(res => res.json())
        .then(data => {return data})
}


export const getBorrower = async (authToken) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return fetch(`${API_URL}/borrower/`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => {return data})
}


//create new profileCreation
export const createUser = async (authToken, body) =>
    fetch(`${API_URL}/user/`,{
        method:'POST',
        mode: "cors",
        headers:{
            'Authorization': `Bearer ${authToken}`,
            'Content-Type':'application/json',
        },
        body:JSON.stringify(body)
    }).then(res => res.json())
        .catch(error=> console.log('error',error))


//create new profileCreation
export const modifyteUser = async (authToken, body) =>
    fetch(`${API_URL}/user/`,{
        method:'PATCH',
        mode: "cors",
        headers:{
            'Authorization': `Bearer ${authToken}`,
            'Content-Type':'application/json',
        },
        body:JSON.stringify(body)
    }).then(res => res.json())
        .catch(error=> console.log('error',error))


//get other user profileCreation
export const getOtherUser = async (authToken, username) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return fetch(`${API_URL}/users/${username}/`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => {return data})
}

 export const getOtherUserByProfile = async (authToken, username) =>{
     const headers = {
         'Accept': 'application/json',
         'Authorization': `Bearer ${authToken}`,
     }
     return fetch(`${API_URL}/users/${username}/?profile_name=1`, {
         mode: "cors",
         headers})
         .then(res => res.json())
         .then(data => {return data})
 }

// get stripe account link
export const getStripeAccountLink = async (authToken)=>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return fetch(`${API_URL}/stripe/account/`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => {
            return data
        })
        .catch(error => console.log("error", error))
}

// get stripe account link update
export const getStripeAccountLinkUpdate = async (authToken)=>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return fetch(`${API_URL}/stripe/account-link/?update=true`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => {
            return data
        })
        .catch(error => console.log("error", error))
}

// create stripe account agreement
export const acceptAgreement = async (authToken, body) =>
    fetch(`${API_URL}/stripe/agreement/`,{
        method:'POST',
        mode: "cors",
        headers:{
            'Authorization': `Bearer ${authToken}`,
            'Content-Type':'application/json',
        },
        body:JSON.stringify(body)
    }).then(res => res.json())
        .catch(error=> console.log('error',error))


// get geolocation
export const userGeolocation = async ()=>
    fetch("https://geolocation-db.com/json/")
        .then(res=>res.json())
        .then(data => data)


//add stripe external payment
export const externalPaymentStripe = async (authToken, body) =>
    await fetch(`${API_URL}/account/external/`,{
        method:'POST',
        mode: "cors",
        headers:{
            'Authorization': `Bearer ${authToken}`,
            'Content-Type':'application/json',
        },
        body:JSON.stringify(body)
    }).then(res => res.json())
        .catch(error=> console.log('error',error))

 /*
  * verify stripe external payment api
  */
 export const externalPaymentStripeVerification = async (authToken) =>{
     const headers = {
         'Accept': 'application/json',
         'Authorization': `Bearer ${authToken}`,
     }
     return fetch(`${API_URL}/account/external/`, {
         mode: "cors",
         headers})
         .then(res => res.json())
         .then(data => {return data})
 }

/*
 * Employment api
 */
export const getAllEmployments = async (authToken) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return fetch(`${API_URL}/employment/`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => {return data})
}

export const addEmployment = async (authToken, body) =>
    fetch(`${API_URL}/employment/`,{
        method:'POST',
        mode: "cors",
        headers:{
            'Authorization': `Bearer ${authToken}`,
            'Content-Type':'application/json',
        },
        body:JSON.stringify(body)
    }).then(res => res.json())
        .catch(error=> console.log('error',error))


export const removeEmployment = async (authToken, id) =>
    fetch(`${API_URL}/employment/${id}`,{
        method:'DELETE',
        mode: "cors",
        headers:{
            'Authorization': `Bearer ${authToken}`,
            'Content-Type':'application/json',
        },
    }).catch(error=> console.log('error',error))


 //update requirement consent
 export const electronicConsentAPI= async (authToken, body) =>
     await fetch(`${API_URL}/electronic-consent/`,{
         method:'POST',
         mode: "cors",
         headers:{
             'Authorization': `Bearer ${authToken}`,
             'Content-Type':'application/json',
         },
         body:JSON.stringify(body)
     }).then(res => res.json())
         .catch(error=> console.log('error',error))


 /*
 * requirement consent
 */
 export const getelectronicConsentAPI = async (authToken) =>{
     const headers = {
         'Accept': 'application/json',
         'Authorization': `Bearer ${authToken}`,
     }
     return fetch(`${API_URL}/electronic-consent/`, {
         mode: "cors",
         headers})
         .then(res => res.json())
         .then(data => {return data})
 }

 /*
 * retrieve document by document type
 */
 export const getSpecificDocumentAPI = async (authToken, id) =>{
     const headers = {
         'Accept': 'application/json',
         'Authorization': `Bearer ${authToken}`,
     }
     return fetch(`${API_URL}/type/${id}/documents/`, {
         mode: "cors",
         headers})
         .then(res => res.json())
         .then(data => {return data})
 }

 /*
  * upload document
  */
 export const postSpecificDocumentAPI= async (authToken, body, id) =>
     await fetch(`${API_URL}/type/${id}/documents/`,{
         method:'POST',
         mode: "cors",
         headers:{
             'Authorization': `Bearer ${authToken}`
         },
         body:(body)
     }).then(res => res.json())
         .catch(error=> console.log('error',error))
