import {hideLoading, showLoading} from "react-redux-loading-bar";
import {getUserFinancialInfoAPI} from "../../utils/api/account";

export const SET_BALANCE = 'SET_BALANCE'


export function setBalance (balance){
    return{
        type:SET_BALANCE,
        balance
    }
}

export function handleAccountBalance(token){
    return (dispatch)=>{
        dispatch(showLoading())
        return getUserFinancialInfoAPI(token)
            .then((balance=>{
                dispatch(setBalance(balance))
            }))
            .then(()=>dispatch(hideLoading()))
    }
}