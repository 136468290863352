import {hideLoading, showLoading} from "react-redux-loading-bar";
import {electronicConsentAPI} from "../../utils/api/userAPI";

export const VIEW_REQUIREMENTS = "VIEW_REQUIREMENTS"
export const UPDATE_REQUIREMENT = "UPDATE_REQUIREMENT"

export function setRequirements(requirement){
    return{
        type : VIEW_REQUIREMENTS,
        requirement,
    }
}

export function updateRequirement(requirement){
    return{
        type: UPDATE_REQUIREMENT,
        requirement
    }
}

export function handleUpdateRequirement(token, data){
    return(dispatch)=>{
        dispatch(showLoading())
        return electronicConsentAPI(token, data)
            .then((requirement)=>{
                dispatch(updateRequirement(requirement))
            })
            .then(()=>dispatch(hideLoading()))
    }
}