import {hideLoading, showLoading} from "react-redux-loading-bar";
import {createUser, getRating, modifyteUser} from "../../utils/api/userAPI";
import {handleInitialData} from "./shared";
import {setRating} from "./rating";

export const SET_AUTHED_USER = 'SET_AUTHED_USER'
export const UPDATE_AUTHED_USER = 'ADD_AUTHED_USER'


export function setAuthedUser (user){
    if(user != null){
        return{
            type: SET_AUTHED_USER,
            user,
        }
    }
    return {
        type: SET_AUTHED_USER,
        user:null,
    }
}

export function updateUser (user){
    return{
        type: UPDATE_AUTHED_USER,
        user,
    }
}

/*
 * creating a profile for the user
 */
export function handleAddUser(token, profile){
    return(dispatch)=>{
        dispatch(showLoading())
        return createUser(token, profile)
            .then(()=>{
                dispatch(handleInitialData(token))
            })
            .then(()=>dispatch(hideLoading()))
    }
}

/*
 * updating a user profile
 */
export function handleUPdateUser(token, profile){
    return(dispatch)=>{
        dispatch(showLoading())
        return modifyteUser(token, profile)
            .then(()=>{
                dispatch(setAuthedUser(profile))
            })
            .then(()=>dispatch(hideLoading()))
    }
}

/*
 * get user's rating
 */
export function handleRating(token){
    return (dispatch)=>{
        dispatch(showLoading())
        return getRating(token)
            .then((data)=>{
                dispatch(setRating(data))
            })
            .then(()=>dispatch(hideLoading()))
    }
}