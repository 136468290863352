import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import {createStore} from 'redux'
import {Provider} from 'react-redux'
import reducer from './redux/reducers'
import middleware from './redux/middleware'
import "./assets/style/default.scss"
import "./assets/style/button.scss"
import "./assets/style/card.scss"
import "./assets/style/color.scss"

const store = createStore(reducer, middleware)
ReactDOM.render(
    <Provider store={store}>
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={window.location.origin}
            audience={process.env.REACT_APP_AUDIENCE}
            useRefreshTokens={true}
        >
            <App />
        </Auth0Provider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
