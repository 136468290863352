
export const ERROR_OCCURRED = 'ERROR_OCCURRED';
export const ERROR_CLEARED = 'ERROR_CLEARED';

export function errorOccurred(errorMessage) {
    if (errorMessage != null){
        return {
            type: ERROR_OCCURRED,
            payload: errorMessage
        };
    }
    return {
        type: ERROR_OCCURRED,
        payload: {}
    };

}

export function errorCleared() {
    return {
        type: ERROR_CLEARED,
    };
}

