import {GET_CONNECTIONS, UNFOLLOW_CONNECTION} from '../actions/connection'
import _ from "lodash";

export default function connection(state={}, action){
    switch (action.type){
        case GET_CONNECTIONS:
            return {
                ...state, ..._.mapKeys(action.connections, 'id')
            }
        case UNFOLLOW_CONNECTION:
            const {[action.connection]:omit,...newState} = state
            return newState
        default:
            return state
    }
}