import {hideLoading, showLoading} from "react-redux-loading-bar";
import {addEmployment, getAllEmployments, removeEmployment} from "../../utils/api/userAPI";

export const ADD_EMPLOYMENT = 'ADD_EMPLOYMENT'
export const GET_EMPLOYMENT = 'GET_EMPLOYMENT'
export const DELETE_EMPLOYMENT = 'DELETE_EMPLOYMENT'

export function addNewEmployment(employment){
    return{
        type:ADD_EMPLOYMENT,
        employment,
    }
}

export function getEmployments(employments){
    return{
        type:GET_EMPLOYMENT,
        employments,
    }
}

export function deleteEmployment(id){
    return{
        type:DELETE_EMPLOYMENT,
        id,
    }
}

export function handleEmploymentData(token){
    return (dispatch)=>{
        dispatch(showLoading())
        return getAllEmployments(token)
            .then((result)=>{
                dispatch(getEmployments(result))
            })
            .then(()=>dispatch(hideLoading()))
    }
}

export function handleAddEmploymentData(token, body){
    return(dispatch)=>{
        dispatch(showLoading())
        return addEmployment(token, body)
            .then((employment)=>{
                dispatch(addNewEmployment(employment))
            })
            .then(()=>dispatch(hideLoading()))
    }
}

export function handleDeleteEmploymentData(token, id){
    return(dispatch)=>{
        dispatch(showLoading())
        return removeEmployment(token, id)
            .then(()=>{
                dispatch(deleteEmployment(id))
            })
            .then(()=>dispatch(hideLoading()))
    }
}