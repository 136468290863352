import {SET_BORROWER} from "../actions/borrower";

export default function borrower(state = {}, action){
    switch(action.type){
        case SET_BORROWER:
            return{
                ...state,
                ...action.borrower
            }
        default:
            return state
    }
}

