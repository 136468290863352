import {UPDATE_REQUIREMENT, VIEW_REQUIREMENTS} from "../actions/requirement"

export default function requirement(state = {}, action){
    switch (action.type){
        case VIEW_REQUIREMENTS:
            return{
                ...state,
                ...action.requirement
            }
        case UPDATE_REQUIREMENT:
            return{
                ...state,
                ...action.requirement
            }
        default:
            return state
    }
}