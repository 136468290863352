import {ADD_EMPLOYMENT, DELETE_EMPLOYMENT, GET_EMPLOYMENT} from "../actions/employment"

import _ from "lodash";

export default function employment(state={}, action){
    switch (action.type){
        case ADD_EMPLOYMENT:
            return{
                ...state,
                [action.employment.id]:action.employment
            }
        case GET_EMPLOYMENT:
            return {
                ...state, ..._.mapKeys(action.employments, 'id')
            }
        case DELETE_EMPLOYMENT:
            const {[action.id]:omit, ...newState} = state
            return newState
        default:
            return state
    }
}
