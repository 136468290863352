import {hideLoading, showLoading} from "react-redux-loading-bar";
import {acceptRequestAPI, refuseRequestAPI, viewRequestAPI} from "../../utils/api/feedAPI";
import {handleGetConnection} from "./connection";

export const ACCEPT_INVITATION = 'ACCEPT_CONNECTION'
export const REFUSE_INVITATION = 'REFUSE_REQUEST'
export const VIEw_INVITATIONS = 'VIEw_INVITATIONS'

function acceptInvitation (invitation){
    return{
        type: ACCEPT_INVITATION,
        invitation,
    }
}

function refuseInvitation (invitation){
    return{
        type: REFUSE_INVITATION,
        invitation,
    }
}

function viewInvitations (invitations){
    return{
        type: VIEw_INVITATIONS,
        invitations,
    }
}

export function handleViewInvitations (token){
    return (dispatch)=> {
        dispatch(showLoading())
        return viewRequestAPI(token)
            .then((invitations)=>{
                dispatch(viewInvitations(invitations))
            })
            .then(()=>dispatch(hideLoading()))
    }
}

export function handleAcceptInvitation(token, id){
    return (dispatch)=>{
        dispatch(showLoading())
        return acceptRequestAPI(token, id)
            .then((result)=>{
                if(result["request"] === true){
                    dispatch(acceptInvitation(id))
                    dispatch(handleGetConnection(token))
                }
            })
            .then(()=>dispatch(hideLoading()))
    }
}

export function handleRefuseInvitation(token, id){
    return (dispatch)=>{
        dispatch(showLoading())
        return refuseRequestAPI(token, id)
            .then((result)=>{
                if(result["request"] === true){
                    dispatch(refuseInvitation(id))
                }
            })
            .then(()=>dispatch(hideLoading()))
    }
}