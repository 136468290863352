import {USER_FEED} from "../actions/userFeed";
import _ from "lodash";

export default function userFeed(state = {}, action){
    switch(action.type){
        case USER_FEED:
            return{
                ...state,  ..._.mapKeys(action.userFeed, "id")
            }
        default:
            return state
    }
}