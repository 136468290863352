import {CREATE_LOAN_REQUEST, USER_LOAN_REQUEST} from '../actions/request'

export default function request(state={},action){
    switch (action.type){
        case CREATE_LOAN_REQUEST:
            return {
                ...state,
                [action.request.id]:action.request
            }
        case USER_LOAN_REQUEST:
            return {
                ...state,
                ...action.requests
            }
        default:
            return state
    }
}