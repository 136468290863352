import {getUser} from '../../utils/api/userAPI'
import { showLoading, hideLoading} from 'react-redux-loading-bar'
import {setAuthedUser} from "./authedUser";
import {setBorrower} from "./borrower";
import {setInvestor} from "./investor";
import {setBalance} from "./balance";
import {setRequirements} from "./requirement";
import {errorOccurred} from "./error";
import {retrieveDocumentType} from "./document";

export function handleInitialData(token){
    return (dispatch)=>{
        dispatch(showLoading())
        return getUser(token)
            .then(({profile, borrower, investor, balance, requirements, documents})=>{
                dispatch(setAuthedUser(profile))
                dispatch(setBorrower(borrower))
                dispatch(setInvestor(investor))
                dispatch(setBalance(balance))
                dispatch(setRequirements(requirements))
                dispatch(retrieveDocumentType(documents))
                dispatch(errorOccurred())
            })
            .then(()=>dispatch(hideLoading()))
    }
}