import {SET_INVESTOR} from '../actions/investor'

export default function investor(state = {}, action){
    switch(action.type){
        case SET_INVESTOR:
            return{
                ...state,
                ...action.investor
            }
        default:
            return state
    }
}