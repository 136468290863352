import {RETRIEVE_DOCUMENT_TYPE} from "../actions/document"

export default function document(state = {}, action){
    switch(action.type){
        case RETRIEVE_DOCUMENT_TYPE:
            return{
                ...state,
                ...action.documents
            }
        default:
            return state
    }
}