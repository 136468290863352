import {DISCOVER_USER, REQUEST_USER} from "../actions/users";
import _ from "lodash";

export default function users(state={}, action) {
    switch (action.type){
        case DISCOVER_USER:
            return{
                ...state, ..._.mapKeys(action.users, "user")
            }
        case REQUEST_USER:
            const {[action.user]:omit, ...newState} = state
            return newState
        default:
            return state
    }
}