import {RETRIEVE_NOTIFICATION} from "../actions/notification";

export default function notification(state = {}, action){
    switch (action.type) {
        case RETRIEVE_NOTIFICATION:
            return{
                ...state,
                ...action.notifications
            }
        default:
            return state
    }
}