import {ERROR_CLEARED, ERROR_OCCURRED} from "../actions/error"

export default function error(state = {}, action){
    switch (action.type) {
        case ERROR_OCCURRED:
            return {
                ...state,
                error: action.payload
            };
        case ERROR_CLEARED:
            return {
                ...state,
                error: {}
            };
        // other cases for handling other actions
        default:
            return state;
    }
}
