import {combineReducers} from 'redux'
import { loadingBarReducer} from 'react-redux-loading-bar'

import authedUser from "./authedUser";
import borrower from "./borrower";
import balance from "./balance"
import investor from "./investor"
import request from './request'
import rating from "./rating";
import feed from "./feed";
import userFeed from "./userFeed";
import employments from "./employment";
import users from "./users";
import invitation from "./invitation";
import connection from "./connection";
import requirement from "./requirement";
import proposal from "./proposal";
import error from "./error"
import transaction from "./transaction";
import notification from "./notification";
import document from "./document";


export default combineReducers({
    authedUser,
    borrower,
    investor,
    balance,
    document,
    error,
    request,
    rating,
    feed,
    employments,
    users,
    invitation,
    connection,
    userFeed,
    notification,
    proposal,
    requirement,
    transaction,
    loadingBar: loadingBarReducer,
})