const API_URL = process.env.REACT_APP_API_URL

export const getLoanReason = async (authToken) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return fetch(`${API_URL}/app/loan-reason/`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => {return data.results})
        .catch(error => console.log("error", error))
}

/*
 * create a loan request
 */
export const createLoanRequest = (authToken,body) =>
    fetch(`${API_URL}/requests/`,{
        method:'POST',
        mode: "cors",
        headers:{
            'Authorization': `Bearer ${authToken}`,
        },
        body:(body)
    }).then(res=> res.json())
        .then(data => {return data})
        .catch(error=> console.log('error',error))


/*
 * create a loan proposal
 */
export const createLoanProposal = (authToken,body) =>
    fetch(`${API_URL}/proposals/`,{
        method:'POST',
        mode: "cors",
        headers:{
            'Authorization': `Bearer ${authToken}`,
        },
        body:(body)
    }).then(res=> res.json())
        .then(data => {return data})
        .catch(error=> console.log('error',error))

/*
 * user loan single requests
 */
export const getUserLoanRequest = async (authToken) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return fetch(`${API_URL}/requests/`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => {return data})
}

/*
 * user loan single proposal
 */
export const getUserLoanProposal = async (authToken) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return fetch(`${API_URL}/proposals/`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => {return data})
}

/*
 * get qualified amount based on score
 */
export const getUserQualifiedAmount = async (authToken) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return fetch(`${API_URL}/borrower/amount/`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => {return data})
}


/*
 * get loan ticket detail
 */
export const getTicketDetailAPI = (authToken,id) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return fetch(`${API_URL}/requests/${id}/`, {
        mode: "cors",
        headers
    })
        .then(res => res.json())
        .then(data => {return data})
        .catch(error => console.log("error", error))
}

/*
 * get loan proposal detail
 */
export const getProposalDetailAPI = (authToken,id) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return fetch(`${API_URL}/proposals/${id}/`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => {return data})
        .catch(error => console.log("error", error))
}

/*
 * other users loan requests
 */
export const getOtherUsersLoanRequest = async (authToken) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/others/requests/`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => {return data})
        .catch(error => console.log("error", error))
}

/*
 * other users loan proposals
 */
export const getOtherUsersLoanProposal = async (authToken) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/others/proposals/`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => {return data})
        .catch(error => console.log("error", error))
}


/*
 * other users loan requests
 */
export const calculateLoanPayment = async (authToken, length, rate, amount) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }

    return await fetch(`${API_URL}/loan/calculator/?length=${length}&rate=${rate}&amount=${amount}`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => {return data})
}

/*
 * get the criteria from a loan proposal
 */
export const getCriteriaProposal = async (authToken, id) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/criteria/${id}/`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => {return data})
}


/*
 * get all loan proposal that is an answer to requests
 */
export const getProposalAsAnswertoTicket = async (authToken) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/response/proposals/`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => {return data})
}


/*
 * get all loan tickets that is an answer to proposal
 */
export const getTicketAnswertoProposal = async (authToken) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/response/tickets/`, {
        mode: "cors",
        headers
    })
        .then(res => res.json())
        .then(data => {return data})
}

/*
 * get all loan tickets that is an answer to proposal
 */
export const getVerificationOfClientProposal = async (authToken, id) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/client/proposal/${id}/`, {
        mode: "cors",
        headers
    })
        .then(res => res.json())
        .then(data => {return data})
}

/*
 * verify ownership of a ticket
 */
export const getVerificationOfClientTicket = async (authToken, id) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/client/ticket/${id}/`, {
        mode: "cors",
        headers
    })
        .then(res => res.json())
        .then(data => {return data})
}

/*
 * accepting or declinging a loan proposal
 */
export const decidingLoanProposal = async (authToken, body, id) =>
    await fetch(`${API_URL}/proposals/decision/${id}/`,{
        method:'POST',
        mode: "cors",
        headers:{
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
        body:JSON.stringify(body)
    }).then(res=> res.json())
        .then(data => {return data})
        .catch(error=> console.log('error',error))


/*
 * accepting or declinging a loan proposal
 */
export const decidingLoanTicket = async (authToken, body, id) =>
    await fetch(`${API_URL}/tickets/decision/${id}/`,{
        method:'POST',
        mode: "cors",
        headers:{
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
        body:JSON.stringify(body)
    }).then(res=> res.json())
        .then(data => {return data})
        .catch(error=> console.log('error',error))

/*
 * get all pending proposals
 */
export const getPendingProposals = async (authToken) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/proposals/pending/`, {
        mode: "cors",
        headers
    })
        .then(res => res.json())
        .then(data => {return data})
}

/*
 * view pending proposals
 */
export const viewPendingProposal = async (authToken, id) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/proposal/${id}/acceptance/`, {
        mode: "cors",
        headers
    })
        .then(res => res.json())
        .then(data => {return data})
}

/*
 * create a loan proposal
 */
export const acceptingLoanProposal = async (authToken, body, id) =>
    await fetch(`${API_URL}/proposal/${id}/acceptance/`,{
        method:'POST',
        mode: "cors",
        headers:{
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
        body:JSON.stringify(body)
    }).then(res=> res.json())
        .then(data => {return data})
        .catch(error=> console.log('error',error))

/*
 * view loans summary
 */
export const viewLoanSummary = async (authToken) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/loans/`, {
        mode: "cors",
        headers
    })
        .then(res => res.json())
        .then(data => {return data})
}

/*
 * view investments summary
 */
export const viewInvestmentSummary = async (authToken) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/investments/`, {
        mode: "cors",
        headers
    })
        .then(res => res.json())
        .then(data => {return data})
}

/*
 * view loans summary
 */
export const viewLoanDetailSummary = async (authToken, id) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/loans/${id}/`, {
        mode: "cors",
        headers
    })
        .then(res => res.json())
        .then(data => {return data})
}

/*
 * view investment summary
 */
export const viewInvestmentDetailSummary = async (authToken, id) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/investments/${id}/`, {
        mode: "cors",
        headers
    })
        .then(res => res.json())
        .then(data => {return data})
}

/*
 * view payment detail and make payment
 */
export const viewPaymentDetailAPI = async (authToken, id) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/payments/${id}/`, {
        mode: "cors",
        headers
    })
        .then(res => res.json())
        .then(data => {return data})
}

/*
 * submit a loan payment
 */
export const submitPaymentDetailAPI = async (authToken, body, id) =>
    await fetch(`${API_URL}/payments/${id}/`,{
        method:'POST',
        mode: "cors",
        headers:{
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
        },
        body:JSON.stringify(body)
    }).then(res=> res.json())
        .then(data => {return data})
        .catch(error=> console.log('error',error))

/*
 * view loans completion summary
 */
export const viewLoanCompletedSummary = async (authToken) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/loans/completed/`, {
        mode: "cors",
        headers
    })
        .then(res => res.json())
        .then(data => {return data})
}

/*
 * view investment completion summary
 */
export const viewInvestmentCompletedSummary = async (authToken) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/investments/completed/`, {
        mode: "cors",
        headers
    })
        .then(res => res.json())
        .then(data => {return data})
}

/*
 * view loans reminder summary
 */
export const viewLoanPaymentReminderAPI = async (authToken) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/loans/reminder/`, {
        mode: "cors",
        headers
    })
        .then(res => res.json())
        .then(data => {return data})
}