import {CREATE_LOAN_PROPOSAL, USER_LOAN_PROPOSAL} from '../actions/proposal'

export default function proposal(state={},action){
    switch (action.type){
        case CREATE_LOAN_PROPOSAL:
            return{
                ...state,
                [action.proposal.id]:action.proposal
            }
        case USER_LOAN_PROPOSAL:
            return{
                ...state,
                ...action.proposals
            }
        default:
            return state
    }
}