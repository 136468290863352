import {GET_RATING} from "../actions/rating";

export default function rating(state = {}, action){
    switch(action.type){
        case GET_RATING:
            return{
                ...state,
                ...action.rating
            }
        default:
            return state
    }
}