import {VIEw_INVITATIONS, ACCEPT_INVITATION, REFUSE_INVITATION} from "../actions/invitation"
import _ from "lodash";

export default function invitation(state={}, action){
    switch (action.type){
        case VIEw_INVITATIONS:
            return{
                ...state, ..._.mapKeys(action.invitations, "id")
            }
        case ACCEPT_INVITATION:
        {
            const {[action.invitation]:omit,...newState} = state
            return newState
        }
        case REFUSE_INVITATION:
            const {[action.invitation]:omit,...nState} = state
            return nState
        default:
            return state
    }
}