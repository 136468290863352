import {hideLoading, showLoading} from "react-redux-loading-bar";
import {getConnectionAPI, unfollowConnectionAPI} from "../../utils/api/feedAPI";

export const GET_CONNECTIONS = 'GET_CONNECTIONS'
export const UNFOLLOW_CONNECTION = 'UNFOLLOW_CONNECTION'



function getConnection (connections){
    return{
        type:GET_CONNECTIONS,
        connections,
    }
}

function unfollowConnection(connection){
    return{
        type:UNFOLLOW_CONNECTION,
        connection
    }
}

/*
 * get all user's connections
 */
export function handleGetConnection(token){
    return (dispatch)=>{
        dispatch(showLoading())
        return getConnectionAPI(token)
            .then((result)=>{
                dispatch(getConnection(result))
            })
            .then(()=>dispatch(hideLoading()))
    }
}

/*
 * unfollow a connection
 */
export function handleUnfollowConnection(token, target, id){
    return (dispatch)=>{
        dispatch(showLoading())
        return unfollowConnectionAPI(token, target)
            .then((result)=>{
                dispatch(unfollowConnection(id))
            })
            .then(()=>dispatch(hideLoading()))
    }
}