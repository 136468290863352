import {SET_BALANCE} from "../actions/balance";

export default function balance(state = {}, action){
    switch(action.type){
        case SET_BALANCE:
            return{
                ...state,
                ...action.balance
            }
        default:
            return state
    }
}