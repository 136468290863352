import {hideLoading, showLoading} from "react-redux-loading-bar";
import {discoverUsersAPI, sendRequestAPI} from "../../utils/api/feedAPI";

export const DISCOVER_USER = 'DISCOVER_USER'
export const REQUEST_USER = 'REQUEST_USER'

function discoverUser(users){
    return{
        type:DISCOVER_USER,
        users
    }
}

function sendRequest (user){
    return{
        type: REQUEST_USER,
        user,
    }
}

export function handleDiscoveringUser(token){
    return (dispatch)=>{
        dispatch(showLoading())
        return discoverUsersAPI(token)
            .then((result)=>{
                dispatch(discoverUser(result))
            })
            .then(()=>dispatch(hideLoading()))
    }
}



export function handleSendRequest(token, body, id){
    return (dispatch)=>{
        dispatch(showLoading())
        return sendRequestAPI(token, body, id)
            .then((result)=>{
                dispatch(sendRequest(id))
            })
            .then(()=>dispatch(hideLoading()))
    }
}