export const SET_INVESTOR = 'SET_INVESTOR'


export function setInvestor(investor){
    return{
        type:SET_INVESTOR,
        investor,
    }
}



// add investor rating