const API_URL = process.env.REACT_APP_API_URL


export const getUserFeedAPI = (authToken) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return fetch(`${API_URL}/social/user-feed/`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => {return data})
        .catch(error=> console.log('error',error))
}

export const getALLFeedAPI = async (authToken) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return fetch(`${API_URL}/social/feed/`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => {return data})
        .catch(error=> console.log('error',error))
}

/*
 * get request detail
 */
export const getRequestDetailAPI = async (authToken,id) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return fetch(`${API_URL}/social/request/${id}/`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => {return data})
        .catch(error=> console.log('error',error))
}

/*
 * get propose detail
 */
export const getProposeDetailAPI = (authToken,id) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return fetch(`${API_URL}/social/propose/${id}/`, {
        mode: "cors",
        headers
    })
        .then(res => res.json())
        .then(data => {return data})
        .catch(error=> console.log('error',error))
}

/*
 * connections api
 */

export const getConnectionAPI = (token) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
    return fetch(`${API_URL}/social/connections/`,{
        mode: "cors",
        headers})
        .then(res =>res.json())
        .then(data =>{return data})
        .catch(error=> console.log('error',error))
}

export const discoverUsersAPI = (token) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
    return fetch(`${API_URL}/social/discover/`,{
        mode: "cors",
        headers})
        .then(res =>res.json())
        .then(data =>{return data})
        .catch(error=> console.log('error',error))
}

export const sendRequestAPI = async (authToken, body, id) =>
    fetch(`${API_URL}/social/connections/${id}/request/`,{
        method:'POST',
        mode: "cors",
        headers:{
            'Authorization': `Bearer ${authToken}`,
            'Content-Type':'application/json',
        },
        body:JSON.stringify(body)
    }).then(res => res.json())
        .then(data =>{return data})
        .catch(error=> console.log('error',error))

export const viewRequestAPI = (token) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
    return fetch(`${API_URL}/social/connections/requests/view/`,{
        mode: "cors",
        headers})
        .then(res =>res.json())
        .then(data =>{return data})
        .catch(error=> console.log('error',error))
}

export const acceptRequestAPI = (token,id) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
    return fetch(`${API_URL}/social/connections/requests/${id}/accept/`,{
        mode: "cors",
        headers})
        .then(res =>res.json())
        .then(data =>{return data})
        .catch(error=> console.log('error',error))
}

export const refuseRequestAPI = (token,id) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
    return fetch(`${API_URL}/social/connections/requests/${id}/refuse/`,{
        mode: "cors",
        headers})
        .then(res =>res.json())
        .then(data =>{return data})
        .catch(error=> console.log('error',error))
}

export const unfollowConnectionAPI = (token,id) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
    return fetch(`${API_URL}/social/connections/${id}/unfollow/`,{
        mode: "cors",
        headers})
        //.then(res =>res.json())
        //.then(data =>data)
        .catch(error=> console.log('error',error))
}

export const getStreamTokenAPI = (token) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
    return fetch(`${API_URL}/social/stream/token/`,{
        mode: "cors",
        headers})
        .then(res =>res.json())
        .then(data =>{return data})
        .catch(error=> console.log('error',error))
}

/*
 * get number of like for a request
 */
export const getRequestReactionAPI = (token, id) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
    return fetch(`${API_URL}/social/request/like/${id}/`,{
        mode: "cors",
        headers})
        .then(res =>res.json())
        .then(data =>{return data})
        .catch(error=> console.log('error',error))
}
/*
 * like a request
 */
export const sendRequestReactionAPI = async (authToken, body, id) =>
    fetch(`${API_URL}/social/request/like/${id}/`,{
        method:'POST',
        mode: "cors",
        headers:{
            'Authorization': `Bearer ${authToken}`,
            'Content-Type':'application/json',
        },
        body:JSON.stringify(body)
    }).then(res => res.json())
        .then(data =>{return data})
        .catch(error=> console.log('error',error))


/*
 * get number of comments of a request
 */
export const getRequestCommentsAPI = (token, id) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
    return fetch(`${API_URL}/social/comments/request/${id}/`,{
        mode: "cors",
        headers})
        .then(res =>res.json())
        .then(data =>{return data})
        .catch(error=> console.log('error',error))
}

/*
 * like a request
 */
export const postRequestCommentsAPI = async (authToken, body, id) =>
    fetch(`${API_URL}/social/comments/request/${id}/`,{
        method:'POST',
        mode: "cors",
        headers:{
            'Authorization': `Bearer ${authToken}`,
            'Content-Type':'application/json',
        },
        body:JSON.stringify(body)
    }).then(res => res.json())
        .then(data =>{return data})
        .catch(error=> console.log('error',error))


/*
 * get number of comments of a proposal
 */
export const getProposalCommentsAPI = (token, id) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
    return fetch(`${API_URL}/social/comments/proposal/${id}/`,{
        mode: "cors",
        headers})
        .then(res =>res.json())
        .then(data =>{return data})
        .catch(error=> console.log('error',error))
}

/*
 * post comment to a proposal
 */
export const postProposalCommentsAPI = async (authToken, body, id) =>
    fetch(`${API_URL}/social/comments/proposal/${id}/`,{
        method:'POST',
        mode: "cors",
        headers:{
            'Authorization': `Bearer ${authToken}`,
            'Content-Type':'application/json',
        },
        body:JSON.stringify(body)
    }).then(res => res.json())
        .then(data =>{return data})
        .catch(error=> console.log('error',error))

/*
 * get number of like for a proposal
 */
export const getProposalReactionAPI = (token, id) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
    return fetch(`${API_URL}/social/propose/like/${id}/`,{
        mode: "cors",
        headers})
        .then(res =>res.json())
        .then(data =>{return data})
        .catch(error=> console.log('error',error))
}
/*
 * like a proposal
 */
export const sendProposeReactionAPI = async (authToken, body, id) =>
    fetch(`${API_URL}/social/propose/like/${id}/`,{
        method:'POST',
        mode: "cors",
        headers:{
            'Authorization': `Bearer ${authToken}`,
            'Content-Type':'application/json',
        },
        body:JSON.stringify(body)
    }).then(res => res.json())
        .then(data =>{return data})
        .catch(error=> console.log('error',error))

/*
 * get notifications
 */
export const getNotificationsAPI = (token) =>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
    }
    return fetch(`${API_URL}/social/notification/`,{
        mode: "cors",
        headers})
        .then(res =>res.json())
        .then(data =>{return data})
        .catch(error=> console.log('error',error))
}

/*
 * mark notification as seen
 */
export const markReadNotificationsAPI = async (authToken, body) =>
    fetch(`${API_URL}/social/notification/`,{
        method:'POST',
        mode: "cors",
        headers:{
            'Authorization': `Bearer ${authToken}`,
            'Content-Type':'application/json',
        },
        body:JSON.stringify(body)
    }).then(res => res.json())
        .then(data =>{return data})
        .catch(error=> console.log('error',error))
