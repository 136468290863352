import {hideLoading, showLoading} from "react-redux-loading-bar";
import {getNotificationsAPI} from "../../utils/api/feedAPI";

export const RETRIEVE_NOTIFICATION = "RETRIEVE_NOTIFICATION"

export function retrieveNotification(notifications){
    return{
        type: RETRIEVE_NOTIFICATION,
        notifications
    }
}
/*
export function handleRetrieveNotification(token){
    return(dispatch) => {
        dispatch(showLoading())

        const fetchNotifications = () => {
            return getNotificationsAPI(token)
                .then((notification) => {
                    dispatch(retrieveNotification(notification))
                })
                .then(() => dispatch(hideLoading()))
        }

        // call the fetchNotifications function every 5 minutes
        fetchNotifications()
            .then(()=>{
                setInterval(fetchNotifications, 300000)
            })

    }
}*/



 export function handleRetrieveNotification(token){
    return(dispatch)=>{
        dispatch(showLoading())
        return getNotificationsAPI(token)
            .then((notification=>{
                dispatch(retrieveNotification(notification))
            }))
            .then(()=>dispatch(hideLoading()))
    }
}
