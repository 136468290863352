const API_URL = process.env.REACT_APP_API_URL

export const getUserFinancialInfoAPI = async (authToken)=>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/account/`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => data)
        .catch(error => console.log("error", error))
}

/*
 *  get all user's transactions
 */
export const getUserFinancialTransactionAPI = async (authToken)=>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/account/transactions/`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => data)
        .catch(error => console.log("error", error))
}

/*
 * get specific transaction
 */
export const getUserDetailFinancialTransactionAPI = async (authToken, id)=>{
    const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${authToken}`,
    }
    return await fetch(`${API_URL}/account/transactions/${id}/`, {
        mode: "cors",
        headers})
        .then(res => res.json())
        .then(data => data)
        .catch(error => console.log("error", error))
}

/*
 * deposit fund to account
 */
export const depositFundAPI= async (authToken, body) =>
    await fetch(`${API_URL}/account/deposit/`,{
        method:'POST',
        mode: "cors",
        headers:{
            'Authorization': `Bearer ${authToken}`,
            'Content-Type':'application/json',
        },
        body:JSON.stringify(body)
    }).then(res => res.json())
        .catch(error=> console.log('error',error))

/*
 * deposit fund to account
 */
export const withdrawFundAPI= async (authToken, body) =>
    await fetch(`${API_URL}/account/withdraw/`,{
        method:'POST',
        mode: "cors",
        headers:{
            'Authorization': `Bearer ${authToken}`,
            'Content-Type':'application/json',
        },
        body:JSON.stringify(body)
    }).then(res => res.json())
        .catch(error=> console.log('error',error))